
import React from 'react';
import {Link} from 'react-router-dom';
import './index.css';


const NotFound = () => {


    return (
        <div className="page_not_found">
          

       
          
        
        
             <p className='page_non_trouvee'> Erreur 404 : Page Manquante – Recette Non Trouvée !<br/><br/> <Link  className="accueil_404" to="/">Accueil</Link></p>

           

     
         
                  
                 
                
       </div>
        
    )
    
}

export default NotFound;